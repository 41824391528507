import bulletImg from "../images/multi-section/bullet.svg";
import blueSpiralImg from "../images/multi-section/blueSpiral.svg";
import greenCircleImg from "../images/hero-header/greenCircle.svg";
import mixBlur1Img from "../images/multi-section/mixblur1.svg";
import orangeBlurImg from "../images/multi-section/orangeblur.svg";
import greenBlurImg from "../images/multi-section/greenblur.svg";
import purpleBlurWithStarImg from "../images/multi-section/purpleblurwithstar.svg";
import redCurvesImg from "../images/multi-section/redcurves.svg";
import redCrossImg from "../images/multi-section/redcross.svg";

export const bullet = bulletImg;
export const blueSpiral = blueSpiralImg;
export const greenCircle = greenCircleImg;
export const mixBlur1 = mixBlur1Img;
export const orangeBlur = orangeBlurImg;
export const greenBlur = greenBlurImg;
export const purpleBlurWithStar = purpleBlurWithStarImg;
export const redCurves = redCurvesImg;
export const redCross = redCrossImg;
