import multisection1 from "images/multi-section/apiIntegration.svg";
import multisection2 from "images/multi-section/dataMapping.svg";
import multisection3 from "images/multi-section/data-movement.svg";

export const multiSectionData = [
  {
    sectionTitle: "API Integration",
    sectionDescription:
      "Effortlessly sync data with Salesforce or vice versa.",
    imageAlignment: "left",
    imageSrc: multisection1,
    bulletPoints: [
      "Bring your own API for instant integration",
      "Out-of-the-box authentication solutions",
      "Easy synchronization with Salesforce data",
    ],
  },
  {
    sectionTitle: "Flexible Data Mapping",
    sectionDescription:
      "Whether mapping application data yourself or letting customers take the lead, our tools make it straightforward",
    imageAlignment: "right",
    imageSrc: multisection2,
    bulletPoints: [
      "User-friendly data mapping capabilities",
      "Allow customers to define their field mappings",
      "Eliminate concerns about Salesforce field usage",
    ],
  },
  {
    sectionTitle: "Dynamic Data Movement",
    sectionDescription:
      "Choose between trigger-based updates or scheduled syncs to keep your Salesforce integration aligned with your operational needs.",
    imageAlignment: "left",
    imageSrc: multisection3,
    bulletPoints: [
      "Sync data with triggers or cron jobs",
      "Tailored integration to match your workflow",
      "Ensure timely and accurate data movement",
    ],
  },
];
