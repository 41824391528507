import React from "react";
import IndexView from "views/IndexView";
import { graphql } from "gatsby";

const IndexPage = ({ data, error }) => {
  if (error) {
    return <div>Error fetching data...</div>;
  }
  return <IndexView props={data.allSanityArticle.nodes} />;
};

export const query = graphql`
  query {
    allSanityArticle(limit: 3, sort: { fields: _createdAt, order: DESC }, filter: { articleType: { eq: "BlogPost" } }) {
      nodes {
        id
        headline
        slug {
          current
        }
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

export default IndexPage;
