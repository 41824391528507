/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import GradientImg from "images/five-card-layout/Gradient.svg";
import GreenHighLightImg from "images/five-card-layout/GreenShape.svg";
import OrangeHighLightImg from "images/five-card-layout/OrangeShape.svg";
import OrangeGreenBgImg from "images/five-card-layout/OrangeGreenBg.svg";
import StarImg from "images/five-card-layout/Star.svg";
import Container from "./Container";
import ReusableButton from "./ReusableButton";

function Item(props) {
  const { borderStrokeColor, borderStrokeWidth, sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) => (theme.palette.mode === "dark" ? "#101010" : "#fff"),
        color: (theme) => (theme.palette.mode === "dark" ? "grey.300" : "grey.800"),
        border: `${borderStrokeWidth}px solid`,
        borderColor: () => borderStrokeColor,
        p: 1,
        borderRadius: 8,
        textAlign: "left",
        fontSize: "0.875rem",
        fontWeight: "700",
        padding: "2rem",
        ...sx,
      }}
      {...other}
    />
  );
}

export function FiveCardLayout({ firstCard, otherCards }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  // set the number of columns based on the screen size
  let gridTemplateColumnsValue;
  if (isSmallScreen) {
    gridTemplateColumnsValue = "repeat(1, 2fr)";
  } else if (isMediumScreen) {
    gridTemplateColumnsValue = "repeat(4, 2fr)";
  } else if (isLargeScreen) {
    gridTemplateColumnsValue = "repeat(3, 2fr)";
  }

  return (
    <div style={{ width: "100%" }}>
      <Container mt={"20px"} maxWidth="lg">
        <Box paddingY={10}>
          <Box
            sx={{
              display: "grid",
              gridAutoFlow: "row",
              gridTemplateColumns: gridTemplateColumnsValue,
              gap: 3,
              position: "relative",
            }}
          >
            <Item
              data-aos={isMobile ? "fade-up" : "fade-right"}
              data-aos-once={false}
              borderStrokeColor={"#0c8ce9"}
              borderStrokeWidth={3}
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                gridColumn: {
                  xs: "span 1",
                  sm: "span 4",
                  md: "span 2",
                  lg: "span 1",
                },
                gridRow: "span 2",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: "#151B35",
                backgroundImage: `url(${GradientImg})`,
                zIndex: 1,
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-4px) scale(1.01)", // Increase the scale on hover
                },
              }}
            >
              <Box
                component={"img"}
                src={GreenHighLightImg}
                sx={{ position: "absolute", top: "2%", left: "65%" }}
                alt="Appnigma AI no ETL constraint icon"
              ></Box>
              <Box
                component={"img"}
                src={OrangeHighLightImg}
                sx={{ position: "absolute", top: "83%", left: "5%" }}
                alt="Appnigma AI no ETL constraint icon"
              ></Box>

              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2}>
                  <Typography variant={"title"} gutterBottom color={theme.palette.text.light}>
                    {firstCard.title}
                  </Typography>
                </Box>
                <Typography variant="body1" color={theme.palette.text.light}>
                  {firstCard.description}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Box marginTop={2} display={"flex"} justifyContent={"flex-start"}>
                  <ReusableButton title={firstCard.buttonName} link={firstCard.buttonLink} />
                </Box>
              </Box>
            </Item>
            {otherCards.map((item, i) => (
              <Item
                data-aos={isMobile ? "fade-up" : "fade-left"}
                data-aos-once={false}
                data-aos-delay={i * 300}
                borderStrokeColor={i === 1 ? "#fecdaa" : "#d4e6ff"}
                borderStrokeWidth={2}
                key={i}
                sx={{
                  position: "relative",
                  display: "block",
                  alignItems: "center",
                  gridColumn: {
                    xs: "span 1",
                    sm: "span 2",
                    md: "span 2",
                    lg: "span 1",
                  },
                  gridRow: "span 1",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundColor: "#fff",
                  zIndex: 1,
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-4px) scale(1.01)", // Increase the scale on hover
                  },
                }}
              >
                <Box display={"flex"} flexDirection={"column"} gap={0}>
                  <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2}>
                    <Box
                      component="img"
                      width={40}
                      height={40}
                      marginBottom={2}
                      src={item.svgLink}
                      alt={`Appnigma AI ${item.title} icon`}
                    ></Box>

                    <Typography variant={"title1"} gutterBottom color={theme.palette.text.header2}>
                      {item.title}
                    </Typography>
                  </Box>
                  <Typography variant="body1" color={theme.palette.text.description2}>
                    {item.description}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                </Box>
              </Item>
            ))}
            <Box
              component={"img"}
              src={OrangeGreenBgImg}
              sx={{
                position: "absolute",
              }}
              alt="gradient background"
            ></Box>
            <Box
              component={"img"}
              src={StarImg}
              marginTop={5}
              sx={{
                position: "absolute",
                top: "99%",
                left: "65%",
                zIndex: 10,
              }}
              alt="star icon"
            ></Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default FiveCardLayout;
