import React from "react";
import Box from "@mui/material/Box";
import Main from "layouts/Main";
import HeroHeader from "components/HeroHeader";

import VideoModal from "../../components/ImageVideoModal";

import MultiSection from "components/MultiSection";
import { multiSectionData } from "data/multiSection";

import integrations from "images/otb-integrations/integrations.webp";
import OTBIntegration from "components/OTBIntegration";

import UserTestimonial from "components/UserTestimonial";
import { testimonials } from "data/testimonials";

import FiveCardLayout from "components/FiveCardLayout";
import { fiveCardLayoutData } from "data/fiveCardLayout";

import FeatureSection from "components/FeatureSection";
import { featureSectionData } from "data/featureSection";

import BlogSection from "components/BlogsSection";

import FaqAccordion from "components/Faq";
import { faqData } from "data/faq";

const IndexView = ({ props }) => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Main bgcolor={"background.paper"}>
        <HeroHeader
          title="Salesforce integration made Easy"
          description="Launch Your Integration on Salesforce AppExchange with Our Code-Generating Platform in No Time."
          buttons={[
            {
              title: "View Pricing",
              link: "/pricing",
            },
            {
            title: "Book a demo",
            link: "/demo",
            }
          ]}
          highlightText={{
            title: {
              text: "Easy",
            },
          }}
        />

        <VideoModal />

        <FiveCardLayout firstCard={fiveCardLayoutData.firstCard} otherCards={fiveCardLayoutData.otherCards} />

        <MultiSection
          mainTitle="Revolutionize Your Salesforce Connectivity"
          mainDescription="Enhance your Salesforce ecosystem with seamless integration, effortless data synchronization, and advanced customization. It's time to redefine enterprise glue."
          sections={multiSectionData}
        />

        <FeatureSection radioSelection="Consulting Firms" contentData={featureSectionData} />

        <OTBIntegration
          subtitle="Coming Soon"
          title="One Platform for All 
CRM Marketplace Apps"
          description="A groundbreaking platform that creates custom apps for all major CRM marketplaces. Simplify deployment, enhance scalability, and connect with global CRM ecosystems effortlessly."
          buttonName="Book a demo"
          buttonLink="/demo"
          image={{
            altText: "Appnigma AI Out of the box Integration image",
            imageSrc: integrations,
          }}
        />

        <FaqAccordion questions={faqData} defaultExpanded={0} />

        <UserTestimonial
          title="User Feedback"
          subtitle="Hear directly from our pioneering users who have experienced firsthand the transformative potential of Appnigma AI."
          testimonials={testimonials}
        />

        <BlogSection blogs={props} />
      </Main>
    </Box>
  );
};

export default IndexView;
