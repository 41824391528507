import React from "react";
import { Grid, Card, CardContent, Typography, Box, CardActions } from "@mui/material";
import bgUrl from "../images/hero-header/staticBackground.svg";
import ReusableButton from "./ReusableButton";
import { useTheme } from "@mui/material/styles";

const BlogSection = ({ blogs }) => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundImage: `url(${bgUrl})` }}>
      <Grid container justifyContent={"center"} sx={{ maxWidth: "lg", mx: "auto" }} position={"relative"}>
        {/* Section Title */}
        <Grid
          item
          xs={12}
          md={8}
          textAlign={"center"}
          padding={"14px"}
          pt={"5rem"}
          gap={"1rem"}
        >
          <Typography variant="description" component="p" color={theme.palette.text.primary2}>
            Blog and News
          </Typography>
          <Typography variant="h2">Our Recent Updates</Typography>
          {/* <Typography variant="body1" textAlign={'center'} component="p" color={'#565A79'} mt={'1rem'} >
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomise here are many.
          </Typography> */}
        </Grid>

        <Grid container spacing={3} padding={"10px"}>
          {/* Blog Cards */}
          {blogs.map((blog) => {
            blog.link = `blogs/${blog.slug.current}`;
            return (
              <Grid item key={blog.id} mx={"auto"} mt={"1rem"} gap={"2rem"} xs={10} sm={6} md={4}>
                <Card
                  sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    color: "#1e2022",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      "& img": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <Box component={'img'} src={blog.image.asset.url} alt={'blog-image'} loading="lazy" />
                  </Box>
                  <CardContent sx={{ paddingLeft: 0 }}>
                    <Typography variant="title1" mb={"2rem"} color={theme.palette.text.header2}>
                      {blog.headline}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <ReusableButton title="Read More" link={blog.link} variant="tertiary" />
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BlogSection;
