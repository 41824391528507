import React from "react";
import { Box } from "@mui/material";
import homeDemoImage from "images/image-video-modal/HomeDemo.webp";
import bgUrl from "images/hero-header/staticBackground.svg";
import Container from "./Container";

const VideoModal = () => {
  return (
    <Box sx={{ backgroundImage: `url(${bgUrl})` }} width={"100%"}>
      <Container sx={{ pt: "0 !important" }}>
        <Box
          sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}
        >
          {/* Home Demo Image */}
          <Box
            component={"img"}
            src={homeDemoImage}
            alt="Appnigma AI preview image"
            sx={{
              maxWidth: "1171px",
              margin: "auto",
              mb: "-13%",
              objectFit: "cover",
            }}
            loading="lazy"
            width={{ xs: "95%", md: "90%" }}
            height={"40%"}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default VideoModal;
