import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  blueSpiral,
  bullet,
  greenBlur,
  greenCircle,
  mixBlur1,
  orangeBlur,
  purpleBlurWithStar,
  redCross,
  redCurves,
} from "images";

const MultiSection = ({ mainTitle, mainDescription, sections }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box position={"relative"}>
      {/* Blue spiral image */}
      <Box
        component={"img"}
        src={blueSpiral}
        alt={"decorative spiral"}
        sx={{ width: "10%", height: "auto", position: "absolute", left: 0, top: { xs: "23%", md: "17%" } }}
        loading="lazy"
      />
      {/* Mixed blur image */}
      <Box
        component={"img"}
        src={mixBlur1}
        alt={"decorative mixed blur"}
        sx={{ width: { xs: "50%", md: "30%" }, height: "auto", position: "absolute", right: 0, top: "8%" }}
        loading="lazy"
      />
      {/* Orange blur image */}
      <Box
        component={"img"}
        src={orangeBlur}
        alt={"decorative orange blur"}
        sx={{ width: { xs: "40%", md: "20%" }, height: "auto", position: "absolute", left: 0, top: "30%" }}
        loading="lazy"
      />
      {/* Purple blur with stars image */}
      <Box
        component={"img"}
        src={purpleBlurWithStar}
        alt={"decorative purple blur with stars"}
        sx={{
          width: { xs: "50%", md: "30%" },
          height: "auto",
          position: "absolute",
          left: "30%",
          top: { xs: "45%", md: "50%" },
        }}
        loading="lazy"
      />
      {/* Red curves image */}
      <Box
        component={"img"}
        src={redCurves}
        alt={"decorative red curves"}
        sx={{ width: "7%", height: "auto", position: "absolute", right: 0, top: "50%" }}
        loading="lazy"
      />
      <Container>
        {/* Main Title and Description */}

        <Box
          position={"relative"}
          margin={"0 auto"}
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "735px",
            textAlign: "center",
            gap: { xs: 3 },
          }}
        >
          <Typography data-aos={"fade-up"} data-aos-once={false} variant="h2">
            {mainTitle}
          </Typography>
          <Typography variant="description2">{mainDescription}</Typography>
          {/* Green circle icon */}
          <Box
            component={"img"}
            src={greenCircle}
            alt={"decorative circle"}
            sx={{ width: "10px", height: "auto", position: "absolute", right: { xs: 0, md: "-60px" }, bottom: "-30px" }}
            loading="lazy"
          />
        </Box>

        {/* Section for images and texts (features/services) */}
        <Box>
          {sections?.map(({ sectionTitle, sectionDescription, imageAlignment, imageSrc, bulletPoints }, index) => {
            return (
              <Grid
                data-aos-delay={index * 500}
                position={"relative"}
                key={`section-${index}`}
                pt={5}
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={{ xs: 2, md: 3 }}
                direction={imageAlignment === "right" ? "row-reverse" : "row"}
                sx={{
                  width: { xs: 1, sm: "90%" },
                  margin: "0 auto !important",
                }}
              >
                {/* Image */}
                <Grid
                  item
                  data-aos={isMobile ? "fade-up" : imageAlignment === "right" ? "fade-left" : "fade-right"}
                  data-aos-once={false}
                  container
                  justifyContent="center"
                  alignItems="center"
                  position={"relative"}
                  xs={12}
                  md={6}
                >
                  <Box
                    component={"img"}
                    height={1}
                    width={{ xs: "80%", md: 1 }}
                    minHeight={300}
                    zIndex={20}
                    loading="lazy"
                    src={imageSrc}
                    alt={`Appnigma AI ${sectionTitle} image`}
                  />
                  {
                    // conditional rendering of green blur image only on the last item
                    index === sections.length - 1 && (
                      <Box
                        component={"img"}
                        src={greenBlur}
                        alt={"decorative green blur"}
                        sx={{ width: "70%", height: "auto", position: "absolute", left: "-70px", bottom: "-100px" }}
                        loading="lazy"
                      />
                    )
                  }
                </Grid>

                {/* Text (Title, description and bullet points) */}
                <Grid
                  data-aos={isMobile ? "fade-up" : imageAlignment === "right" ? "fade-right" : "fade-left"}
                  data-aos-once={false}
                  textAlign={{ xs: "center", md: "start" }}
                  item
                  xs={12}
                  md={6}
                >
                  <Box>
                    <Typography
                      margin={{ xs: "0 auto 10px auto", md: "0 0 10px 0" }}
                      sx={{
                        width: "fit-content",
                        zIndex: 20,
                      }}
                      position={"relative"}
                      variant={"title"}
                      component={"h4"}
                      gutterBottom
                    >
                      {sectionTitle}
                      {index === 0 && (
                        <Box
                          className="rotate"
                          component={"img"}
                          src={redCross}
                          alt={"decorative cross"}
                          sx={{ width: "15%", height: "auto", position: "absolute", right: "-12%", bottom: "36%" }}
                          loading="lazy"
                        />
                      )}
                    </Typography>
                    <Typography variant={"description2"} textAlign={"start"}>
                      {sectionDescription}
                    </Typography>
                  </Box>
                  {/* Bullet points list */}
                  {
                    <List>
                      {bulletPoints.map((point, index) => (
                        <ListItem
                          sx={{
                            pl: 0,
                            margin: { xs: "0 auto", md: 0 },
                            width: { xs: "70%", md: 1 },
                            textAlign: { xs: "center", md: "start" },
                          }}
                          key={`point-${index}`}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: "34px",
                            }}
                          >
                            <Box
                              component={"img"}
                              src={bullet}
                              alt="bullet point icons"
                              sx={{
                                width: "20px",
                                height: "20px",
                              }}
                              loading="lazy"
                            />
                          </ListItemIcon>
                          <ListItemText
                            disableTypography={true}
                            sx={{
                              textAlign: "start",
                              fontWeight: "700",
                              color: "#565A79",
                            }}
                            primary={point}
                          />
                        </ListItem>
                      ))}
                    </List>
                  }
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Container>
    </Box>
  );
};

MultiSection.propTypes = {
  mainTitle: PropTypes.string,
  mainDescription: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      sectionTitle: PropTypes.string,
      sectionDescription: PropTypes.string,
      imageSrc: PropTypes.string,
      imageAlignment: PropTypes.oneOf(["left", "right"]).isRequired,
      bulletPoints: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};

export default MultiSection;
